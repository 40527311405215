import axios from "axios";
// require('dotenv').config()

axios.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
      "token"
    )}|${sessionStorage.getItem("refresh")}|${sessionStorage.getItem(
      "ip_public"
    )}|${sessionStorage.getItem("ip_local")}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
// const adviseApi = process.env.VUE_APP_ADVISE_API
const adviseApi = "https://jliswalq43.execute-api.us-east-1.amazonaws.com/prod";
// const adviseApi = "http://localhost:5000";

export const getReply = async (ticketId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/ticket/reply?ticket_id=${ticketId}`
    );
    return data;
  } catch (error) {
    return [];
  }
};
export const postReply = async (data) => {
  try {
    const response = await axios.post(`${adviseApi}/ticket/reply`, data);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const updateStatusTicket = async (ticketId, data) => {
  try {
    const response = await axios.put(
      `${adviseApi}/ticket/ticket/status-ticket/${ticketId}`,
      data
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getBrief = async (userId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/brief/back-office?user_id=${userId}`
    );
    return data;
  } catch (error) {
    return [];
  }
};

export const updateQualificationTicket = async (ticketId, data) => {
  try {
    const response = await axios.put(
      `${adviseApi}/ticket/ticket/qualification/${ticketId}`,
      data
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
