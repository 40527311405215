<template>
	<div>
		<v-toolbar tile absolute color="white" short class="toolbar_tick">
			<div>
				<v-toolbar-title
					><span>{{ title }}</span></v-toolbar-title
				>
			</div>
		</v-toolbar>
		<v-container fluid style="margin-top: 5rem; margin-bottom: 1rem; background-color: #f3f4f8" class="d-flex">
			<!-- <v-row justify="end" align="center" v-if="!isAdmin"> -->
			<v-row justify="end" align="center">
				<v-col cols="auto">
					<div class="filter-data-table">Filter by:</div>
				</v-col>
				<v-col cols="2">
					<v-select
						hide-details
						:disabled="isLoading"
						dense
						primary
						flat="flat"
						v-model="statusFilter"
						:items="lstStatus"
						item-text="name"
						item-value="id"
						label="Status"
					></v-select>
				</v-col>
				<v-col cols="2" v-if="isAdmin && title === 'Back Office'">
					<v-select
						hide-details
						:disabled="isLoading"
						dense
						primary
						item-text="name"
						item-value="id"
						flat="flat"
						v-model="userFilter"
						:items="lstUsers"
						label="User"
						@change="filterUser"
					></v-select>
				</v-col>
				<v-col cols="2" v-if="title === 'Ticket Status'">
					<v-select
						hide-details
						:disabled="isLoading"
						dense
						primary
						item-text="name"
						item-value="id"
						flat="flat"
						v-model="responsibleFilter"
						:items="lstResponsibles"
						label="Responsible"
						@change="filterResponsible"
					></v-select>
				</v-col>
				<v-col cols="1" align="center">
					<v-btn
						color="#4CAF50"
						@click="refreshData"
						:disabled="isLoading"
						:ripple="false"
						title="Refresh List"
					>
						<v-icon color="white" dark> mdi-refresh </v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-row justify="space-between" style="padding-left: 1rem !important; padding-right: 1rem !important">
			<v-col cols="12">
				<div class="mb-5">
					<template v-if="isLoading">
						<div class="text-center ma-12">
							<v-progress-circular :indeterminate="true" :size="100" :width="5" color="light-blue">
							</v-progress-circular>
						</div>
					</template>
					<template>
						<v-data-table
							class="ticket-status-table"
							v-if="!isLoading"
							:headers="headersFilter"
							:items="ticketsTemp"
							item-key="id"
							fixed-header
							hide-default-footer
							disable-pagination
							must-sort
							mobile-breakpoint="0"
							:search="statusFilter"
							:header-props="{ 'sort-icon': 'mdi-arrow-up' }"
							style="width: 100%"
						>
							<template v-slot:header.created_at="{ header }">
								<div class="b-content sorteable-header">{{ header.text }}</div>
							</template>
							<template v-slot:header.kin_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.reason_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.concept_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.description="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.qualification="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.closed_at="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.responsible_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.user_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.statusTicket_name="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.concept_sla="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>
							<template v-slot:header.view="{ header }">
								<div class="b-content">{{ header.text }}</div>
							</template>

							<template v-slot:item.created_at="{ item }">
								{{ formatDate(item.created_at) }}
							</template>
							<template v-slot:item.closed_at="{ item }">
								{{ formatDate(item.created_at, item.concept_sla) }}
							</template>
							<template v-slot:item.statusTicket_name="{ item }">
								<div
									class="mx-1 py-2 my-3 bg"
									:style="{
										background: `#${
											item.status_ticket_id == 1
												? '9FD895'
												: item.status_ticket_id == 2
												? 'FF7E7E'
												: 'FFBF00'
										}`,
									}"
								>
									{{ item.statusTicket_name }}
								</div>
							</template>
							<template v-slot:item.responsible_name="{ item }">
								<template>
									<div class="my-2">
										{{ item.responsible_name }}
									</div>
								</template>
							</template>
							<template v-slot:item.user_name="{ item }">
								<template>
									<div class="my-2">
										{{ item.user_name }}
									</div>
								</template>
							</template>

							<template v-slot:item.kin_name="{ item }">
								<div class="my-2">
									{{ item.kin_name }}
								</div>
							</template>
							<template v-slot:item.reason_name="{ item }">
								<div class="my-2">
									{{ item.reason_name }}
								</div>
							</template>
							<template v-slot:item.concept_name="{ item }">
								<div class="my-2">
									{{ item.concept_name }}
								</div>
							</template>
							<template v-slot:item.description="{ item }">
								<div class="my-2">
									{{ item.description }}
								</div>
							</template>

							<template v-slot:item.qualification="{ item }">
								<v-rating
									:readonly="item.statusTicket_name != 'Closed' || isAdmin || item.qualification > 0"
									background-color="black"
									color="#00A5FF"
									empty-icon="far fa-star"
									full-icon="fas fa-star"
									half-icon="fas fa-star-half-alt"
									length="5"
									@input="changeCalification($event, item.id)"
									size="12"
									v-model="item.qualification"
									:value="item.qualification"
								></v-rating>
							</template>
							<template v-slot:item.view="{ item }">
								<v-btn
									icon
									color="#00a5ff"
									@click="handleClickRow(item)"
									:disabled="!isAdmin && item.status_ticket_id == 1"
								>
									<v-icon> mdi-eye </v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</template>
				</div>
			</v-col>
		</v-row>

		<ticket-reply
			v-if="openModal"
			@closeDialog="
				openModal = false;
				ticketSelected = {};
			"
			:ticket="ticketSelected"
			:dialog="openModal"
			:isAdmin="isAdmin"
		/>
	</div>
</template>
<script>
import TicketReply from "../dialogs/TicketReply.vue";
import { updateQualificationTicket } from "./../../services/services";
export default {
	components: {
		TicketReply,
	},
	data() {
		return {
			ticketsTemp: [],
			initialTickets: [],
			openModal: false,
			userFilter: "",
			statusFilter: "",
			lstUsers: [],
			responsibleFilter: "",
			lstResponsibles: [],
			lstStatus: [
				{ id: "", name: "All" },
				{ id: "Open", name: "Open" },
				{ id: "In Process", name: "In Process" },
				{ id: "Closed", name: "Closed" },
			],
			ticketSelected: {},
			headersFilter: [],
			headers: [
				{
					text: "Date",
					align: "start",
					sortable: true,
					value: "created_at",
					width: "15%",
					allUsers: true,
				},
				{
					text: "Kind of request",
					align: "start",
					sortable: false,
					value: "kin_name",
					width: "10%",
					allUsers: true,
				},
				{
					text: "Reason",
					align: "start",
					sortable: false,
					value: "reason_name",
					width: "10%",
					allUsers: true,
				},
				{
					text: "Concept",
					align: "start",
					sortable: false,
					value: "concept_name",
					width: "10%",
					allUsers: true,
				},
				{
					text: "Description",
					align: "start",
					sortable: false,
					value: "description",
					width: "10%",
					allUsers: true,
				},
				{
					text: "Satisfaction",
					align: "start",
					sortable: false,
					value: "qualification",
					width: "10%",
					allUsers: true,
				},
				{
					text: "Deadline",
					align: "start",
					sortable: false,
					value: "closed_at",
					width: "10%",
					allUsers: true,
				},

				{
					text: "Responsible",
					align: "start",
					sortable: false,
					value: "responsible_name",
					width: "10%",
					needAdmin: false,
				},
				{
					text: "User",
					align: "start",
					sortable: false,
					value: "user_name",
					width: "10%",
					needAdmin: true,
				},

				{
					text: "Status",
					align: "start",
					sortable: false,
					value: "statusTicket_name",
					width: "10%",
					filterable: true,
					allUsers: true,
				},
				{
					text: "SLA",
					align: "center",
					sortable: false,
					value: "concept_sla",
					width: "5%",
					allUsers: true,
				},
				{
					text: "View",
					value: "view",
					sortable: false,
					filterable: false,
					allUsers: true,
				},
			],
		};
	},
	methods: {
		filterUser(id) {
			if (id === "All" || id === "") {
				this.ticketsTemp = this.initialTickets;
			} else {
				this.ticketsTemp = this.initialTickets.filter((t) => t.user_id === id);
			}
		},
		filterResponsible(id) {
			if (id === "All" || id === "") {
				this.ticketsTemp = this.initialTickets;
			} else {
				this.ticketsTemp = this.initialTickets.filter((t) => t.responsible_id === id);
			}
		},
		formatDate(date, addDays = 0) {
			if (date === null) return "-";
			const fec = new Date(date);
			fec.setDate(fec.getDate() + addDays);
			return fec.toLocaleString("en-US", {
				month: "long",
				day: "2-digit",
				year: "numeric",
			});
		},
		handleClickRow(item) {
			this.ticketSelected = item;
			this.openModal = true;
		},
		async changeCalification(qualification, ticketId) {
			await updateQualificationTicket(ticketId, { qualification });
		},
	},
	props: ["tickets", "isLoading", "title", "isAdmin", "refreshData"],

	created() {
		this.headersFilter = this.headers.filter((h) => h.allUsers || h.needAdmin == this.isAdmin);
	},
	watch: {
		tickets: {
			handler: function (val) {
				this.ticketsTemp = val;
				this.initialTickets = val;
				this.lstUsers = [
					{ id: "", name: "All" },
					...new Set(
						this.initialTickets.map((t) => {
							return { id: t.user_id, name: t.user_name };
						})
					),
				];
				this.lstResponsibles = [
					{ id: "", name: "All" },
					...new Set(
						this.initialTickets.map((t) => {
							return { id: t.responsible_id, name: t.responsible_name };
						})
					),
				];
			},
			deep: true,
			inmediate: true,
		},
	},
};
</script>

<style scoped>
.filter-data-table {
	font-size: 15px;
	font-family: "Oxygen", sans-serif;
	font-weight: 900;
	color: #2c3852;
}

.ticket-status-table.v-data-table td {
	border: none !important;
}
::v-deep tbody tr:nth-of-type(even) {
	background-color: #f3f4f7 !important;
}
::v-deep tbody tr td {
	vertical-align: text-top !important;
	font-size: 12px !important;
	font-family: "Oxygen" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	color: #5d6b88 !important;
}
.bg {
	color: white;
	border-radius: 5px;
	text-align: center;
}

.b-content {
	color: #2c3852;
	margin: 6px 0;
}
.toolbar_tick {
	padding: 0px !important;
	margin: 0px !important;
	left: 0px;
	width: 100%;
	margin-top: 100px;
}

.toolbar_tick span {
	font-size: 20px;
	font-family: "Oxygen", sans-serif;
	font-weight: 900;
	color: #2c3852;
	padding-left: 1rem;
}
</style>
