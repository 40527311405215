<template>
	<v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" :width="1000">
		<v-card class="card-brief-data">
			<div class="div-close mr-3" style="z-index: 1">
				<v-icon color="#00a5ff" @click="$emit('closeDialog')">mdi-close-thick</v-icon>
			</div>
			<v-stepper alt-labels non-linear class="stepper">
				<v-stepper-header class="stepper-header">
					<v-stepper-step editable color="#00a5ff" class="ss steper-complete-tab" step="1" cl>
						Datos Generales
					</v-stepper-step>
					<v-divider class="divider"></v-divider>
					<v-stepper-step color="#00a5ff" step="2" editable class="ss"> Datos Específicos </v-stepper-step>
					<v-divider class="divider"></v-divider>
					<v-stepper-step class="ss" editable color="#00a5ff" step="3"> Análisis FODA </v-stepper-step>
				</v-stepper-header>
				<v-stepper-content step="1">
					<v-container fluid class="px-5 mt-5 content-stepper">
						<v-row no-gutters>
							<label class="label-cabecera">1. Identificación y Tipo de Empresa</label>
						</v-row>
						<v-container>
							<v-row no-gutters>
								<v-col cols="6">
									<label class="label-title">Nombre Compañia</label>
									<p class="des-content my-0">
										{{ brief.brief.nombreCompania }}
									</p>
								</v-col>
								<v-col cols="6">
									<label class="label-title">Direccion</label>
									<p class="des-content my-0">{{ brief.brief.direccion }}</p>
								</v-col>
							</v-row>
							<v-row no-gutters>
								<v-col cols="6">
									<label class="label-title">Ciudad</label>
									<p class="des-content my-0">{{ brief.brief.ciudad }}</p>
								</v-col>
								<v-col cols="6">
									<label class="label-title">Contacto</label>
									<p class="des-content my-0">{{ brief.brief.contacto }}</p>
								</v-col>
							</v-row>
							<v-row no-gutters>
								<v-col cols="6">
									<label class="label-title">Descripción (Industria Actividad)</label>
									<p class="des-content my-0">{{ brief.brief.descripcion }}</p>
								</v-col>
								<v-col cols="6">
									<label class="label-title">Categoría</label>
									<p class="des-content my-0">{{ brief.brief.categoria }}</p>
								</v-col>
							</v-row>
							<!-- <v-row no-gutters>
                <v-col cols="12">
                  <label class="label-title"
                    >Aspecto Diferencial de la marca o empresa</label
                  >
                  <p class="des-content my-0">
                    {{ brief.brief.aspectoDiferencial }}
                  </p>
                </v-col>
              </v-row> -->
							<v-row no-gutters>
								<v-col cols="6">
									<label class="label-title">Tamaño Empresa</label>
									<p class="des-content my-0">
										{{ brief.brief.tamanioEmpresa }}
									</p>
								</v-col>
								<v-col cols="6">
									<label class="label-title"> Cantidad de Empleados</label>
									<p class="des-content my-0">
										{{ brief.brief.cantidadEmpleados }}
									</p>
								</v-col>
							</v-row>
							<v-row no-gutters>
								<v-col cols="6">
									<label class="label-title">Mercado</label>
									<p class="des-content my-0">{{ brief.brief.mercado }}</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="py-0"><label class="label-head">Competencia</label> </v-col>
								<v-container class="py-0">
									<v-col cols="12" class="py-0">
										<ul>
											<li
												v-for="(empresa, index) in brief.brief.empresas"
												:key="'liempresa' + index"
												class="my-0"
											>
												<p class="des-content my-0">{{ empresa.name }}</p>
											</li>
										</ul>
									</v-col>
									<v-col cols="12" class="py-0">
										<v-container class="py-0">
											<v-row
												v-for="(compentencia, index) in brief.brief.competencias"
												no-gutters
												:key="'competenciaBrief' + index"
											>
												<v-col cols="4">
													<label class="label-title">Red Social</label>
													<p class="des-content my-0">
														{{ compentencia.redSocial }}
													</p>
												</v-col>
												<v-col cols="8">
													<label class="label-title">Red Social</label>
													<p class="des-content my-0">
														{{ compentencia.linkWeb }}
													</p>
												</v-col>
											</v-row>
										</v-container>
									</v-col>
								</v-container>
							</v-row>
						</v-container>

						<v-row no-gutters class="mt-5">
							<label class="label-cabecera">2. Presencia</label>
						</v-row>
						<v-container>
							<v-row no-gutters class="des-content">
								<label class="label-title">Pais</label> &emsp;{{
									getPais(brief.brief.pais, brief.brief.lstPaises)
								}}
							</v-row>
						</v-container>
						<v-row>
							<v-col cols="12" class="py-0"><label class="label-head">Locales</label> </v-col>
							<v-container class="py-0">
								<v-col cols="12" class="py-0">
									<v-container class="py-0">
										<v-row
											v-for="(local, index) in brief.brief.locales"
											no-gutters
											:key="'localBrief' + index"
										>
											<v-col cols="2" class="py-0 des-content">
												<label class="label-title">Local {{ index + 1 }}</label>
											</v-col>
											<v-col cols="3" class="py-0 des-content">
												<label class="label-title">Departamento</label> &emsp;{{
													getDepartamento(local.departamento, brief.brief.lstDepartamentos)
												}}
											</v-col>
											<v-col cols="3" class="py-0 des-content">
												<label class="label-title">Provincia</label> &emsp;{{
													getProvincia(local.provincia, local.lstProvincias)
												}}
											</v-col>
											<v-col cols="3" class="py-0 des-content">
												<label class="label-title">Distrito</label> &emsp;{{
													getDistrito(local.distrito, local.lstDistritos)
												}}
											</v-col>
										</v-row>
									</v-container>
								</v-col>
							</v-container>
						</v-row>
						<v-row no-gutters class="mt-5">
							<label class="label-cabecera">3. Tipo de Cliente</label>
						</v-row>
						<v-container>
							<v-row no-gutters class="des-content" style="text-transform: uppercase">
								<label class="label-title" style="text-transform: none">Marketing</label>
								&emsp;{{ brief.brief.marketing }}
							</v-row>
							<v-container
								v-if="brief.brief.marketing == 'b2b' || brief.brief.marketing == 'ambos'"
								class="py-0"
							>
								<v-row no-gutters>
									<v-col cols="1" class="py-0"></v-col>
									<v-col cols="2" class="des-content py-0">
										<label class="label-title">Perfil B2B</label>
									</v-col>
									<v-col cols="6" class="des-content py-0">
										<label class="label-title">Empresa</label> &emsp;{{
											brief.brief.perfilB2B.empresa
										}}
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="3" class="py-0"></v-col>
									<v-col cols="8" class="des-content py-0">
										<label class="label-title">Sectores:</label>
										&emsp;{{ brief.brief.perfilB2B.sectores.toString() }}
									</v-col>
								</v-row>
							</v-container>

							<v-container
								v-if="brief.brief.marketing == 'b2c' || brief.brief.marketing == 'ambos'"
								class="py-0 mt-5"
								style="border-top: 1px solid #2c3852"
							>
								<v-row no-gutters>
									<v-col cols="1" class="py-0"></v-col>
									<v-col cols="2" class="des-content py-0">
										<label class="label-title">Perfil B2C</label>
									</v-col>
									<v-col cols="6" class="des-content py-0">
										<label class="label-title">Empresa</label> &emsp;{{
											brief.brief.perfilB2C.empresa
										}}
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="3" class="py-0"></v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Género</label>
										&emsp;{{ brief.brief.perfilB2C.genero }}
									</v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Edad</label> &emsp;{{ brief.brief.perfilB2C.edad }}
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="3" class="py-0"></v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Ubicación</label>
										&emsp;{{ brief.brief.perfilB2C.ubicacion }}
									</v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Necesidades</label> &emsp;{{
											brief.brief.perfilB2C.necesidades
										}}
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="3" class="py-0"></v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Motivaciones</label>
										&emsp;{{ brief.brief.perfilB2C.motivaciones }}
									</v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Hábitos y Hobbies</label>
										&emsp;{{ brief.brief.perfilB2C.habitos }}
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="3" class="py-0"></v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">NSE</label>
										&emsp;{{ brief.brief.perfilB2C.nse }}
									</v-col>
									<v-col cols="4" class="des-content py-0">
										<label class="label-title">Hábitos en Social Media</label>
										&emsp;{{ brief.brief.perfilB2C.habitosSocialMedia }}
									</v-col>
								</v-row>
							</v-container>
						</v-container>
					</v-container>
				</v-stepper-content>
				<v-stepper-content step="2">
					<v-container fluid class="px-5 mt-5 content-stepper">
						<v-row no-gutters>
							<label class="label-cabecera">4. Organización</label>
						</v-row>
						<v-container>
							<v-row no-gutters class="des-content">
								<label class="label-title">Gerencias:</label>
								&emsp;{{ brief.brief.gerencias.toString() }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title">Adopción de la transformación digital:</label>
								&emsp;{{ brief.brief.adopcionTransformacionDigital.toString() }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title">Número de empleados capacitados en RRSS:</label>
								&emsp;{{ brief.brief.numeroEmpleadosCapacitadosRRSS }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title">¿Marketing Digital?</label>
								<p
									v-bind:style="{
										color: brief.brief.existeArea == 'No' ? 'red' : '#00a5ff',
									}"
								>
									&emsp;{{ brief.brief.existeArea == "No" ? "No existe" : "Existe" }}
								</p>
								<p v-if="brief.brief.existeArea != 'No'">&emsp; y lo conforman</p>
								<p v-if="brief.brief.existeArea != 'No'" style="color: #00a5ff">
									&emsp;{{ brief.brief.numeroEmpleadosArea }}
								</p>
							</v-row>
						</v-container>
						<v-row no-gutters>
							<label class="label-cabecera">5. Presencia en Redes Sociales</label>
						</v-row>
						<v-container>
							<v-row no-gutters>
								<v-container class="py-0">
									<v-col cols="12" class="py-0">
										<v-container class="py-0">
											<v-row
												v-for="(red, index) in brief.brief.redesSociales"
												no-gutters
												:key="'redesSociales' + index"
											>
												<v-col cols="1" class="py-0 des-content"> </v-col>
												<v-col cols="3" class="py-0 des-content">
													<label class="label-title">Red Social</label>
													&emsp;{{ red.nombre }}
												</v-col>
												<v-col cols="8" class="py-0 des-content">
													<label class="label-title">Link de la Web</label>
													&emsp;{{ red.link }}
												</v-col>
											</v-row>
										</v-container>
									</v-col>
								</v-container>
							</v-row>
						</v-container>
						<v-row no-gutters>
							<label class="label-cabecera">6. Marketing Digital</label>
						</v-row>
						<v-container>
							<v-row no-gutters class="des-content">
								<label class="label-title">Website:</label>
								&emsp;{{ brief.brief.website }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title">Campañas de publicidad realizadas en:</label>
								&emsp;{{ brief.brief.campaniasRealizadas.toString() }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title"> Monto Promedio de publicidad mensual en Redes (USD):</label>
								&emsp;{{ brief.brief.montoPromedioPublicidad }}
							</v-row>
						</v-container>
					</v-container>
				</v-stepper-content>
				<v-stepper-content step="3">
					<v-container fluid class="px-5 mt-5 content-stepper">
						<v-row no-gutters>
							<label class="label-cabecera">1. FODA</label>
						</v-row>
						<v-container>
							<v-row no-gutters class="des-content">
								<label class="label-title">Fortalezas de la Empresa:</label>
								&emsp;{{ brief.brief.fortalezas.toString() }}
							</v-row>
							<v-row no-gutters class="des-content" v-if="brief.brief.otrasFortalezas">
								<label class="label-title">Otras Fortalezas:</label>
								&emsp;{{ brief.brief.otrasFortalezasDesc }}
							</v-row>
							<v-row no-gutters class="des-content">
								<label class="label-title">Debilidades de la Empresa:</label>
								&emsp;{{ brief.brief.debilidades.toString() }}
							</v-row>
							<v-row no-gutters class="des-content" v-if="brief.brief.otrasDebilidades">
								<label class="label-title">Otras Debilidades:</label>
								&emsp;{{ brief.brief.otrasDebilidadesDesc }}
							</v-row>
						</v-container>
						<v-row no-gutters>
							<label class="label-cabecera">2. Análisis del Posicionamiento</label>
						</v-row>
						<v-container>
							<v-row>
								<v-col cols="12">
									<label class="label-title">
										¿Cuál es el aspecto diferencial de su marca o empresa?</label
									>
									<p class="des-content my-0">
										{{ brief.brief.aspectoDiferencial }}
									</p>
								</v-col>
								<v-col cols="12">
									<label class="label-title">
										Enumere todos los puntos clave destacados dentro de su empresa</label
									>
									<p class="des-content my-0">
										{{ brief.brief.puntosClave }}
									</p>
								</v-col>
								<v-col cols="12">
									<label class="label-title">
										Objetivo: Defina detalladamente el objetivo o la respuesta que desea obtener del
										informe</label
									>
									<p class="des-content my-0">
										{{ brief.brief.objetivosInforme }}
									</p>
								</v-col>
							</v-row>
						</v-container>
					</v-container>
				</v-stepper-content>
			</v-stepper>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		brief: {
			type: Object,
		},
		dialog: {
			type: Boolean,
		},
	},
	created() {},

	methods: {
		getPais(paisId, lstPaises) {
			const pais = lstPaises.find(({ id }) => id == paisId);
			return pais.name;
		},
		getDepartamento(departamentoId, lstDepartamentos) {
			const departamento = lstDepartamentos.find(({ id }) => id == departamentoId);
			return departamento.name;
		},
		getProvincia(provinciaID, lstProvincias) {
			const provincia = lstProvincias.find(({ id }) => id == provinciaID);
			return provincia.name;
		},
		getDistrito(distritoId, lstDistritos) {
			const distrito = lstDistritos.find(({ id }) => id == distritoId);
			return distrito.name;
		},
	},
};
</script>
<style scoped>
.div-close {
	position: absolute;
	top: 0;
	right: 0;
}
.label-cabecera {
	font-size: 16px;
	font-weight: 700;
	color: #00a5ff;
}
.label-head {
	font-size: 15px;
	font-weight: 700;
}
.label-title {
	font-size: 13px;
	font-weight: 700;
}
.des-content {
	font-size: 12px;
	font-weight: 400;
}
::v-deep.stepper {
	margin: auto;
	align-content: center;
	max-width: 1100px !important;
	border: none;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

::v-deep.stepper-header {
	margin: 35px 35px 0px 35px !important;
	max-width: 1050px !important;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
.divider {
	margin: 35px -76px 0 !important;
	border-width: 3px;
}

.divider-success {
	background: #00a5ff;
	border-width: 3px;
}
::v-deep.v-stepper__step:not(.v-stepper__step--inactive) .v-stepper__label {
	color: #00a5ff !important;
}
::v-deep.ss .v-stepper__label {
	text-align: center !important;
	font-family: "Oxygen" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 13px !important;
}
::v-deep.v-stepper__step.v-stepper__step--complete + hr {
	border-color: #00a5ff !important;
	background: #00a5ff !important;
}
.content-stepper {
	max-height: 500px;
	overflow-y: auto;
}
.content-stepper::-webkit-scrollbar {
	width: 5px;
	background: white;
}

.content-stepper::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background-color: #00a5ff;
}

.content-stepper::-webkit-scrollbar-track {
	background-color: white;
}
</style>
