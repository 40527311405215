import "regenerator-runtime/runtime";
import "./set-public-path";
import "./global.css";
import Vuetify from "./plugins/vuetify";
import Store from "./store/main";
// You can export Vue components from this file and import them into your microfrontends
// export { default as BaseInput } from "./components/inputs/BaseInput.vue";
export { default as TicketReply } from "./components/dialogs/TicketReply.vue";
export { default as BriefData } from "./components/dialogs/BriefData.vue";
export { default as TableTicket } from "./components/tables/TableTicket.vue";

//** MODAL SCREEN SIZE*//
export { default as ScreensizeModal } from "./components/modal/ScreensizeModal.vue";

export const store = Store;
export const vuetify = Vuetify;
if (module.hot) {
  module.hot.accept();
}
