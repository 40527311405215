<template>
	<div>
		<v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" :width="800">
			<v-card class="card-ticket-reply">
				<div class="div-close mr-5">
					<v-icon color="#00a5ff" @click="$emit('closeDialog')">mdi-close-thick</v-icon>
				</div>
				<v-container class="px-5 mt-5">
					<div class="conversation-ticket" ref="scrollToMe">
						<div v-for="(mensaje, index) in lstMessages" :key="'msgAdviseTicket' + index" class="mt-3 mb-3">
							<div v-if="getPropio(mensaje)" class="d-flex flex-row justify-end">
								<div class="burbuja div-propio div-message-propio">
									<div class="message-content">{{ mensaje.response }}</div>
								</div>
							</div>
							<div v-else class="d-flex flex-row">
								<div class="burbuja div-otro div-message-other">
									<span class="label-nickname">{{ mensaje.user_name }}</span>
									<div class="message-content">{{ mensaje.response }}</div>
								</div>
							</div>
						</div>
					</div>
					<v-progress-linear indeterminate v-if="loading"></v-progress-linear>
				</v-container>
				<v-container>
					<v-row justify="center" align="start">
            <v-col cols="1" v-if="!isAdmin && ticket.status_ticket_id != 2"></v-col>
						<v-col cols="3" v-if="isAdmin" align="end">
							<v-btn color="#00a5ff" class="btn-view-brief" @click="viewBrief()" :loading="loadingBrief">
								View Brief
								<v-icon color="white" dark> mdi-office-building </v-icon>
							</v-btn>
						</v-col>
						<v-col :cols="auto" v-if="ticket.status_ticket_id != 2">
							<v-text-field
								outlined
								v-model="message"
								v-on:keyup.enter="sendMessage()"
								:disabled="loading"
								class="txt-input"
							></v-text-field>
						</v-col>
						<!-- <v-col cols="1" v-if="ticket.status_ticket_id != 2">
              <v-btn
                color="#00a5ff"
                :disabled="!message || loading"
                @click="sendMessage()"
              >
                <v-icon color="white" dark> mdi-send </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" v-if="isAdmin && ticket.status_ticket_id != 2">
              <v-btn
                color="#FF7E7E"
                title="Close Ticket"
                @click="closeTicket()"
              >
                <v-icon color="white" dark> mdi-content-save-check </v-icon>
              </v-btn>
            </v-col> -->
						<v-col cols="auto" justify="center">
							<v-btn
								v-if="ticket.status_ticket_id != 2"
								color="#00a5ff"
								:disabled="!message || loading"
								@click="sendMessage()"
							>
								<v-icon color="white" dark> mdi-send </v-icon>
							</v-btn>
							<v-btn
								v-if="isAdmin && ticket.status_ticket_id != 2"
								color="#FF7E7E"
								title="Close Ticket"
								:disabled="loading"
								@click="closeTicket()"
							>
								<v-icon color="white" dark> mdi-content-save-check </v-icon>
							</v-btn>
							<v-btn
								v-if="isAdmin && ticket.status_ticket_id == 2"
								color="#FFBF00"
								title="Reopen Ticket"
								:disabled="loading"
								@click="reopenTicket()"
							>
								<v-icon color="white" dark> mdi-undo-variant </v-icon>
							</v-btn>
							<v-btn color="#4CAF50" title="Refresh" :disabled="loading" @click="refreshData()">
								<v-icon color="white" dark> mdi-refresh </v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1" v-if="!isAdmin && ticket.status_ticket_id != 2"></v-col>
					</v-row>
				</v-container>
			</v-card>
			<brief-data v-if="openModal" :brief="briefSelected" :dialog="openModal" @closeDialog="openModal = false" />
		</v-dialog>
	</div>
</template>

<script>
import { default as BriefData } from "./BriefData.vue";

import { getBrief, getReply, postReply, updateStatusTicket } from "./../../services/services";
export default {
	components: { BriefData },
	data() {
		return {
			lstMessages: [],
			message: "",
			loading: false,
			loadingBrief: false,
			openModal: false,
			briefSelected: {},
		};
	},
	props: {
		ticket: {
			type: Object,
		},
		dialog: {
			type: Boolean,
		},
		isAdmin: {
			type: Boolean,
		},
	},
	async created() {
		await this.refreshData();
	},

	methods: {
		async refreshData() {
			this.loading = true;
			this.lstMessages = await getReply(this.ticket.id);
      console.log(this.lstMessages)
			this.scrollToElement();
			this.loading = false;
		},
		getPropio(mensaje) {
			const client_id = sessionStorage.getItem("client_id");
			return client_id == mensaje.user_id;
		},
		async sendMessage() {
			const client_id = sessionStorage.getItem("client_id");
			this.loading = true;
			const data = {
				response: this.message,
				ticket_id: this.ticket.id,
				user_id: client_id,
			};
			const message = await postReply(data);
			if (this.isAdmin && this.ticket.status_ticket_id == 1) {
				await updateStatusTicket(this.ticket.id, { status_ticket_id: 3 });
				this.ticket.status_ticket_id = 3;
				this.ticket.statusTicket_name = "In Process";
			}
			this.message = "";
			this.lstMessages.push(message);
			this.scrollToElement();
			this.loading = false;
		},
		closeTicket() {
			updateStatusTicket(this.ticket.id, { status_ticket_id: 2 });
			this.ticket.status_ticket_id = 2;
			this.ticket.statusTicket_name = "Closed";
		},
		reopenTicket() {
			updateStatusTicket(this.ticket.id, { status_ticket_id: 3 });
			this.ticket.status_ticket_id = 3;
			this.ticket.statusTicket_name = "In Process";
		},
		async viewBrief() {
			this.loadingBrief = true;
			const brief = await getBrief(this.ticket.user_id);
			this.briefSelected = { brief: JSON.parse(brief[0].brief) };
			this.loadingBrief = false;
			this.openModal = true;
		},

		scrollToElement() {
			setTimeout(() => {
				const el = this.$refs.scrollToMe;
				if (!el) return;
				el.scrollTop = el.scrollHeight;
			}, 300);
		},
	},
};
</script>
<style scoped>
.div-close {
	position: absolute;
	top: 0;
	right: 0;
}
.card-ticket-reply {
	height: 450px;
	overflow: hidden;
}
.burbuja {
	max-width: 70%;
	border-radius: 7.5px;
}
.div-propio {
	background-color: #00a5ff;
}
.div-otro {
	background-color: rgb(39, 52, 68);
}
.div-message-propio {
	content: "";
	display: block;
	position: relative;
	border-top: 10px solid transparent;
	border-right: 15px solid white;
	padding: 0px 20px 10px;
}

.div-message-other {
	content: "";
	display: block;
	position: relative;
	border-top: 10px solid transparent;
	border-left: 15px solid white;
	padding: 0px 20px 20px;
}

.label-nickname {
	color: #00a5ff;
	font-weight: 700;
	font-size: 14px;
}
.message-content {
	color: white;
	font-weight: 400;
	font-size: 12px;
}
.conversation-ticket {
	overflow-y: auto;
	min-height: 320px;
	max-height: 320px;
}
.conversation-ticket::-webkit-scrollbar {
	position: absolute;
	width: 5px;
	height: 0;
}
.conversation-ticket::-webkit-scrollbar-thumb:hover {
	background: hsla(0, 0%, 100%, 0.4);
}
.conversation-ticket::-webkit-scrollbar-thumb {
	background: #00a5ff;
	border-radius: 0;
	opacity: 1;
}
::v-deep.btn-view-brief .v-btn__content {
	color: white;
	font-size: 12px;
	font-weight: 400px;
	text-transform: none;
}
::v-deep.txt-input .v-text-field__slot {
	font-size: 12px;
}
::v-deep.txt-input .v-text-field__slot input {
	padding: 0px;
}
</style>
